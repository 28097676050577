@import '../../../../common/styles/styles.less';

.side-menu-header {
	--collapseButtonTransition: opacity 0.1s, background-color 0.25s, right 0.2s, left 0.2s;

	@media screen and (max-width: 650px) {
		--collapseButtonTransition: none;
	}

	background-color: @go-sidebar-blue;
	height: @ts-unit-triple;
	z-index: 1;
	border-bottom: @go-sidebar-blue-dark 1px solid;
	box-shadow: 0 @ts-unit-quarter @ts-unit-half rgba(@go-sidebar-blue-dark, 0.4);
	position: relative;
	background-image: @go-logo;
	background-size: 58px 58px;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;

	.side-menu-header-collapse-button {
		align-items: center;
		border-radius: @ts-unit-plus;
		flex: 1;
		height: @ts-unit-plus;
		justify-content: center;
		position: absolute;
		right: 0;
		top: @ts-unit-half + @ts-unit-quarter;
		transition: var(--collapseButtonTransition);
		width: @ts-unit-plus;
		z-index: 1;

		&:hover {
			& when (var(--isMobileView) = false) {
				animation: bounce 0.5s linear;
				opacity: 1;
				background-color: @go-sidebar-blue-dark;
			}
		}

		i {
			&.animateToTheLeft {
				.defaultStyleHeaderIcon();
				transform: scaleX(-1);
			}

			&.animateToTheRight {
				.defaultStyleHeaderIcon();
				transform: rotate(0deg);
				width: @ts-unit-half - @ts-unit-quarter;
				background-color: transparent;
				opacity: 0;
			}
		}
	}

	.defaultStyleHeaderIcon {
		position: absolute;
		background-image: @nav-arrow;
		background-size: 15px 15px;
		background-repeat: no-repeat;
		background-position: center;
		border-radius: 30px;
		height: 30px;
		width: 30px;
	}

	@media screen and (max-width: 650px) {
		.side-menu-header-collapse-button {
			cursor: default;

			&:has(i.ts-icon-close) {
				cursor: pointer;

				&:hover {
					background-color: @go-sidebar-blue-dark;
				}
			}

			.side-menu-header-collapse-button.hasCloseIcon {
				cursor: pointer;

				&:hover {
					background-color: @go-sidebar-blue-dark;
				}
			}

			&:hover {
				animation: none;
			}

			i {
				color: #445c66;
				top: @ts-unit-quarter;
				left: @ts-unit-quarter;
			}
		}
	}
}
