@import '../../../../../common/styles/styles.less';

.tab-link {
	list-style-type: none;
	margin: 0;
	padding: 0 @ts-unit-half !important;
	cursor: pointer;
	position: relative;

	&::before {
		content: none !important;
	}

	&:first-child {
		padding-left: 0 !important;

		a {
			padding-left: @ts-unit-half;
			min-width: 30px;
			position: relative;
		}
	}

	&:hover {
		a {
			color: #0f1519;
		}
	}

	&:has(.active:not(.has_ts_icon_other)) {
		color: #0f1519;

		&::after {
			content: '';
			height: 1px;
			background-color: #0f151a;
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			top: auto;
			width: auto;
			z-index: 1;
		}
	}

	a {
		text-decoration: none !important;
		display: block;
		width: 100%;
		color: #4f6b77;
		font-size: @ts-fontsize;
		font-weight: @ts-fontweight-semibold;
		white-space: nowrap;
		padding: @ts-unit-half 0;

		&.active {
			color: #0f1519;
		}

		span:has(i.ts-icon-other) {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: @ts-unit-half;
			background-color: #fff;
			display: flex;
			align-items: flex-end;

			&::before {
				position: absolute;
			}
		}
	}
}
