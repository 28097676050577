@import '../../../common/styles/styles.less';

#go-app #go-app-container {
	@import url('../../../common/styles/not-mobile.less');
}

#main-sideMenu {
	--sideMenuWidth: 360px;
	--sideMenuTransition: min-width 0.3s ease-out, max-width 0.3s ease-out;
	overflow: auto;

	@media screen and (max-width: 650px) {
		--sideMenuWidth: 100vw;
		--sideMenuTransition: none;
	}

	width: var(--sideMenuWidth);
	max-width: var(--sideMenuWidth);
	min-width: var(--sideMenuWidth);
	transition: var(--sideMenuTransition);
	display: flex;
	flex-direction: column;
	z-index: 3;
	height: 100vh;
	max-height: 100vh;

	.side-menu-header {
		transition: background-size 0.3s ease-out;
	}

	.side-menu-panel {
		flex: 1;
		position: relative;

		& > menu {
			max-height: calc(100vh - 183px);
			overflow: hidden;
		}

		.side-menu-panel-option {
			a {
				display: flex;
				color: hsl(200, 21%, 92%);
				font-weight: @ts-fontweight-semibold;

				&:hover {
					cursor: pointer;
					color: hsl(200, 21%, 92%);
					text-decoration: none;
				}
				i {
					transition: left 0.3s ease-out;
					flex-shrink: 0;
				}
				span {
					transition: opacity 0.2s ease-out 0.1s;
				}
			}
			.request-status {
				&.pending {
					i {
						color: orange;
						top: @ts-unit-double;
					}
				}
			}
			.secondary {
				font-weight: @ts-fontweight-light;
				font-size: @ts-fontsize-mini;
			}
		}
	}

	&.side-menu-collapsed {
		width: @ts-unit-triple;
		max-width: @ts-unit-triple;
		min-width: @ts-unit-triple;
		transition:
			min-width 0.3s ease-out,
			max-width 0.3s ease-out;

		.side-menu-header {
			background-size: 44px 44px;
			transition: background-size 0.3s ease-out;

			animation: purple-shadow-glow-animation 0.7s ease-in-out;

			.side-menu-header-collapse-button {
				opacity: 0;
				transition: opacity 0.1s ease-out;
			}

			&:hover {
				.side-menu-header-collapse-button {
					background-color: @go-sidebar-blue;
					opacity: 1;
					width: @ts-unit - @ts-unit-quarter;
					border-radius: 0 15px 15px 0;
					right: -@ts-unit-half - @ts-unit-quarter;
					animation: bounce 0.5s linear;

					i {
						opacity: 1;
					}
				}
			}
		}

		.side-menu-panel {
			.side-menu-panel-option {
				a {
					align-items: center;
					justify-content: center;

					i {
						position: static;
						left: 30px;
						transition: left 0.3s ease-out;
					}
					span {
						opacity: 0;
						overflow: hidden;
						display: none;
						transition:
							opacity 0.1s ease-out,
							display 0.1s 0.2s;
					}
				}
			}
		}
	}

	&.side-menu-mobile-view-open {
		--sideMenuWidth: 100vw;
	}

	@media screen and (max-width: 650px) {
		& {
			position: absolute;
			transform: translateX(100%);

			&.side-menu-mobile-view-open {
				transform: translateX(0);
			}
		}
	}
}
