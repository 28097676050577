@import url('../common/styles/styles.less');

.ts-app {
	// on react pages we use flex, we do not want to extend the page content beyond the viewport
	&:has(#go-app-container) {
		min-width: unset;
		width: unset;
		max-width: unset;
	}
}

#go-app {
	box-sizing: content-box;

	.close-aside-button {
		color: inherit;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			background-color: hsla(198, 20%, 83%, 0.5);
			text-decoration: none;
		}
	}

	.form-wrapper {
		flex-direction: column;
		display: flex;
		flex: 1;
	}

	#main-sideMenu {
		height: 100%;
		max-width: 100%;
		display: flex;

		.side-menu {
			@import url('../components/organisms/sideMenu/style.less');
		}
	}

	#go-app-container {
		height: 100%;
		max-width: 100%;
		display: flex;

		.content:not(.modal-panel-content) {
			margin: 0;
			max-width: inherit;
			box-sizing: border-box;
			padding: 20px;
		}

		.main-content {
			overflow: hidden;
			padding: 20px;
		}

		.content-right {
			display: flex;
			max-width: 100%;
			flex-direction: column;
			min-width: 0;
			flex: 1;
			isolation: isolate;
			position: relative;

			&:empty {
				display: none;
			}
		}

		.ts-table {
			// TODO: override remove when main side menu is fixed
			min-width: 100%;
		}

		.team-overview {
			padding-bottom: @ts-unit;
			flex-grow: 1;

			.page-main {
				height: 100% !important;
			}
		}

		.page-main {
			margin: 0;
			min-width: 0;
			flex: 1;

			.main-content {
				overflow: hidden;

				.page-settings {
					height: 100% !important;
					overflow: auto;

					.user-report-header {
						padding-top: 0;
						padding-bottom: @ts-unit;
					}
				}
			}
		}

		#requestSidebar {
			@import url('../components/organisms/sideMenu/sideMenuMyRequestAside/style.less');
		}
	}
}
