@import '../../../../common/styles/styles.less';

.main-header {
	width: 100%;
	display: flex;
	position: relative;
	background-color: #fff;
	flex-direction: column;
	font-weight: @ts-fontweight;
	color: #3f545e;
	font-size: @ts-fontsize;
	line-height: @ts-unit;
	padding: 0 @ts-unit-half;
	box-sizing: border-box;
	border-bottom: 1px solid @ts-color-gray-light;

	&_name {
		text-decoration: none;
		font-size: @ts-fontsize-big;
		padding: @ts-unit @ts-unit-half;
		line-height: @ts-unit;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		height: @ts-unit-triple;
		white-space: nowrap;
	}

	&_top {
		flex-direction: row;
		align-items: center;
		transform: translateX(-@ts-unit-half);
		border: none;

		.hamburger {
			border: 0;
			height: @ts-unit-double;
			width: @ts-unit-double;
			padding: @ts-unit-half;
			color: #0f1519;
			cursor: pointer;
			transition: background-color 0.2s ease-in-out;
			display: none;
		}

		.ts-icon-close {
			margin-left: auto;
			cursor: pointer;
			padding: @ts-unit-half 0;
		}
	}
}

@media screen and (max-width: 650px) {
	.main-header {
		&_name {
			padding-left: 0;
		}

		&_top {
			transform: translateX(0px);
			padding-left: 0;

			.hamburger {
				display: block;
			}
		}
	}
}
