@import (reference) url('../../../../common/styles/styles.less');

.mktoForm {
	margin: 0 auto;
	width: 318px !important;
	font-family: unset !important;
	padding-bottom: @ts-unit;

	.mktoFormRow {
		.mktoFieldDescriptor {
			.mktoFieldWrap {
				.mktoGutter {
					width: 5px !important;
				}

				.mktoField {
					width: 308px !important;
				}

				.mktoLogicalField {
					width: 308px !important;

					.mktoField {
						display: none;
					}
				}

				.mktoLabel .mktoAsterix {
					display: none;
				}

				.mktoError {
					top: @ts-unit-plus;
					right: auto !important;
					left: @ts-unit-quarter;
				}

				input,
				select {
					box-shadow: unset;
					border: 1px solid #cad7dc;
					border-radius: 3px;
					height: 30px;
					font-size: @ts-fontsize-mini;
					font-family: unset !important;
					padding-left: 10px;
					padding-top: 4px;
					padding-bottom: 4px;
				}
			}

			.mktoOffset {
				display: none;
			}
		}

		.mktoFormCol .mktoOffset {
			width: 5px !important;
		}
	}

	.mktoButtonRow {
		width: 100%;

		.mktoButtonWrap {
			display: block;
			width: 100%;
			text-align: center;
			margin-left: unset !important;

			button {
				background: @go-purple !important;
				color: @go-white !important;
				border-radius: @ts-unit;
				text-transform: uppercase !important;
				font-family: unset !important;
				box-shadow: unset !important;
				border: unset !important;
				height: @ts-unit-double;
				font-size: @ts-fontsize-medium;
				width: 308px;
			}

			button:hover {
				background: @ts-color-pink-dark !important;
				text-decoration: none;
			}
		}
	}
}

.request-demo-loading {
	text-align: center;

	span {
		font-size: @ts-fontsize-medium;
	}

	button {
		width: 310px;
	}
}
