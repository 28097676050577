@import '../../../../common/styles/styles.less';

.side-menu-footer {
	display: flex;
	align-items: center;
	box-sizing: content-box;
	height: 60px;
	z-index: 1;
	background-color: @go-sidebar-blue-lite;
	border-top: @go-sidebar-blue-dark 1px solid;
	box-shadow: 0 -5px 10px rgba(@go-sidebar-blue-dark, 0.4);
	overflow: hidden;
	white-space: nowrap;

	.side-menu-footer-user-image {
		margin: 10px;

		span {
			display: inline-block;
			height: 40px;
			width: 40px;
			border-radius: 20px;
			background: @go-gradient-purple;
			font-size: 18px;
			font-weight: 500;
			line-height: 40px;
			text-align: center;
			color: @go-white;
		}
	}

	.side-menu-footer-user-details {
		display: flex;
		flex-direction: column;
		color: @go-white;

		span:nth-child(2) {
			font-size: 12px;
			opacity: 0.7;
		}
	}

	.side-menu-footer-user-buttons {
		display: flex;
		margin-left: auto;

		a {
			margin: 15px;
			text-decoration: none;
			color: @go-gray-lite-dark;

			:hover {
				color: @go-gray-lite-lite;
			}
		}
		a:nth-child(2) {
			margin-right: 30px;
		}
	}
}
