.go-mixin-label {
	font-size: @ts-fontsize-mini;
	font-weight: @ts-fontweight-bold;
	text-transform: uppercase;
}

// button loading animation from UI components
@-webkit-keyframes move-progress-bar {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: @ts-unit-double @ts-unit-double;
	}
}
@keyframes move-progress-bar {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: @ts-unit-double @ts-unit-double;
	}
}
@keyframes preload {
	0% {
		background-position: 125% 0%;
	}
	50% {
		background-position: -25% 0%;
	}
	100% {
		background-position: -25% 0%;
	}
}

@keyframes bounce {
	0% {
		margin-right: 0;
	}

	50% {
		margin-right: @ts-unit-eighth;
	}

	100% {
		margin-right: 0;
	}
}

@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

// TODO: use for approvals list
.go-mixin-item-separator {
	content: '•';
	padding: 0 @ts-unit-eighth 0 @ts-unit-quarter;
}
.go-mixin-align-hcenter {
	left: 50%;
	position: absolute;
	transform+_: translateX(-50%);
}
.go-mixin-align-vcenter {
	top: 50%;
	position: absolute;
	transform+_: translateY(-50%);
}
.go-mixin-circle(@size: @ts-unit-half, @color: @go-purple) {
	background-color: @color;
	border-radius: 50%;
	height: @size;
	width: @size;
}
// only in virtualCard.less
.go-mixin-blur-font(@shadow-color) {
	color: transparent;
	text-shadow: @shadow-color 0 0 10px;
}
.go-mixin-card-embossed-font {
	text-shadow: 1px 1px @go-black;
}
// only in cardIcon.less
.go-mixin-card-icon(@size: @ts-unit-double, @digits-scale: 1, @go-minicard-status: 0) {
	background: @go-gradient-purple;
	border-radius: @size / 7;
	color: @go-white;
	height: @size;
	position: relative;
	width: (@size / 2) * 3;

	span {
		bottom: @size / 8;
		font-size: (@size / 3) * @digits-scale;
		line-height: (@size / 4) * @digits-scale;
		position: absolute;
		right: @size / 8;
	}
	&:before {
		@status-icon-diameter: (@size / 8) * 9;
		background-image: @go-minicard-status;
		background-size: cover;
		content: '';
		height: @status-icon-diameter;
		left: (-@size / 8) * 5;
		position: absolute;
		top: @size / 6;
		width: @status-icon-diameter;
	}
}
@preload-background: @ts-color-gray-lightest;
@preload-foreground: @ts-color-gray-light;
.go-mixin-preload-gradient-anim {
	animation: preload 2s infinite linear;
	background-color: @preload-background;
	background-image: linear-gradient(
		90deg,
		@preload-background 0%,
		@preload-background 40%,
		@preload-foreground 50%,
		@preload-background 60%,
		@preload-background 100%
	);
}
.go-mixin-text-overflow {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	-ms-text-overflow: ellipsis;
}

.go-mixin-style-webkit-scroller(@scrollbar-color) {
	overflow: auto;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-thumb:vertical {
		margin: 5px;
		background-color: @scrollbar-color;
		-webkit-border-radius: 5px;
	}
	&::-webkit-scrollbar-button:start:decrement,
	&::-webkit-scrollbar-button:end:increment {
		height: 5px;
		display: block;
	}
}

.go-mixin-hide-table-body-column-separator(@separator-index) {
	@left-cell: @separator-index;
	@right-cell: @separator-index + 1;
	td:nth-child(@{left-cell}) {
		border-right: 0;
	}
	td:nth-child(@{right-cell}) {
		border-left: 0;
	}
}

.go-mixin-hide-table-column-separator(@separator-index) {
	@left-cell: @separator-index;
	@right-cell: @separator-index + 1;
	th:nth-child(@{left-cell}),
	td:nth-child(@{left-cell}) {
		border-right: 0;
	}
	th:nth-child(@{right-cell}),
	td:nth-child(@{right-cell}) {
		border-left: 0;
	}
}

.go-mixin-hide-table-column-separator-by-class(@left-col-class, @right-col-class) {
	td.@{left-col-class},
	th.@{left-col-class} {
		border-right: 0;
	}
	td.@{right-col-class},
	th.@{right-col-class} {
		border-left: 0;
	}
	th.@{left-col-class},
	th.@{right-col-class} {
		overflow: visible;
		button {
			overflow: visible;
		}
	}
}

.go-mixin-button-side-highlight() {
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	right: auto;
	top: 0;
	width: 5px;
}

.optional {
	position: relative;

	&::before {
		content: '(optional)';
		position: absolute;
		top: @ts-unit-half;
		right: 0;
		color: @go-gray-medium;
	}
}

.info-message-container {
	.go-mixin-align-hcenter();
	.go-mixin-align-vcenter();
	color: @go-gray-dark-lite;
	text-align: center;

	> span {
		font-size: @ts-fontsize-xbig;
		line-height: @ts-unit-plus;
	}
}

.go-mixin-tooltip(@top, @left) {
	.tooltip {
		background-color: @go-sidebar-blue;
		border-radius: @ts-unit-quarter;
		color: white;
		left: @left;
		padding: @ts-unit-half;
		position: absolute;
		top: @top;
		visibility: hidden;
		white-space: nowrap;
		z-index: 1;
	}

	&:hover {
		.tooltip {
			visibility: visible;
		}
	}
}
