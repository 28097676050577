& {
	width: 300px;
	max-width: 300px;
	min-width: 300px;
	left: -1px;
	opacity: 1;
	display: block;
	.ts-panel.sidebar-drawer {
		&:before {
			box-shadow: 3px 0 10px 1px rgba(0, 0, 0, 0.4);
			content: ' ';
			height: 100%;
			position: absolute;
			top: @ts-unit-triple;
			width: 1px;
			z-index: 200;
		}
		background-color: @go-sidebar-blue-dark;
		height: 100%;
		overflow: hidden;
		position: absolute;
		top: 0;
		padding-top: 0;
		width: @ts-width-aside - @ts-unit;
		z-index: -1;
		header {
			border-bottom: @go-black 1px solid;
			color: @go-white;
			font-size: @ts-fontsize-medium;
			font-weight: @ts-fontweight-semibold;
			height: @ts-unit-triple;
			margin-top: 1px;
			padding: @ts-unit;
		}
		.close-drawer {
			align-items: center;
			border-radius: 30px;
			display: flex;
			flex: 1;
			height: 30px;
			justify-content: center;
			position: absolute;
			right: 0;
			top: 15px;
			transition:
				min-width 0.3s ease-out,
				max-width 0.3s ease-out;
			width: 30px;
			z-index: 1;
			&:hover {
				cursor: pointer;
			}
			i {
				background-size: 100% 100%;
				height: @ts-unit-half + @ts-unit-quarter;
				transform: scaleX(-1);
				width: @ts-unit-half + @ts-unit-quarter;
			}
		}
	}
}
