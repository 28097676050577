@import url('variables.less');
@import url('ts-variables.less');
@import url('mixins.less');

@background-color: rgb(231, 236, 239);

html,
body {
	background-color: @background-color !important;
}
