@import '../../../common/styles/styles.less';

.main-footer {
	--footerHeight: @ts-unit-triple;

	background-color: #eff2f5;
	position: relative;
	transition: opacity 0.2s;
	height: var(--footerHeight);
	max-height: var(--footerHeight);
	min-height: var(--footerHeight);
	white-space: nowrap;
	display: flex;
	align-items: center;
	padding: 0 @ts-unit-half;
	justify-content: flex-end;
	z-index: 2000;

	&::after {
		height: 1px;
		position: absolute;
		left: 0;
		right: 0;
		pointer-events: none;
		background-color: #cad7dc;
		content: '';
		top: 0;
	}

	button {
		margin-left: @ts-unit-half;
	}
}
