// this file contains css that must not be applied on mobile and cannot be overriden by mobile.less

// The behavior of Chrome and Safari on iOS for pseudo elements created on hover is to create the pseudo element on the
// first click, and trigger the click event on the second click, which sucketh. So we won't create on hover pseudo
// elements on mobile. https://css-tricks.com/annoying-mobile-double-tap-link-issue/

@new-sidebar-width: @ts-width-aside + @ts-unit-double;

.sidebar .ts-menu > li > a:hover:before {
	.go-mixin-button-side-highlight();
	background-color: @go-purple;
}

request-info,
[request-info] {
	.request-status:hover {
		&.created {
			&:before {
				background-color: @go-gray-lite-lite;
			}
		}
		&.approved {
			&:before {
				background-color: @go-status-success;
			}
		}
		&.pending {
			&:before {
				background-color: @go-status-pending;
			}
		}
		&.declined,
		&.error {
			&:before {
				background-color: @go-status-error;
			}
		}
	}
}

#main-sideMenu.hasSidemenuOpen + .content-right {
	min-width: calc(100% - @new-sidebar-width);
	transition:
		min-width 0.5s,
		max-width 0.5s;
	width: calc(100% - @new-sidebar-width);

	conversation {
		.add-message {
			left: @new-sidebar-width;
			transition: 0.25s;
		}
	}
}

#main-sideMenu.side-menu-open + .content-right {
	min-width: calc(100% - @new-sidebar-width);
	transition:
		min-width 0.5s,
		max-width 0.5s;
	width: calc(100% - @new-sidebar-width);

	conversation {
		.add-message {
			left: @new-sidebar-width;
			transition: 0.25s;
		}
	}
}

#main-sideMenu.hasDoubleSidemenuCollapsed + .content-right {
	min-width: calc(100% - @new-sidebar-width);
	transition:
		min-width 0.25s,
		max-width 0.25s;
	width: calc(100% - @new-sidebar-width);
	margin-left: auto;
	conversation {
		.add-message {
			left: @ts-unit-triple;
		}
	}
}

#main-sideMenu.side-menu-collapsed + .content-right {
	min-width: calc(100% - @new-sidebar-width);
	width: calc(100% - @new-sidebar-width);
	margin-left: auto;
	conversation {
		.add-message {
			left: @ts-unit-triple;
		}
	}
}

#main-sideMenu.side-menu-collapsed [ts-variant='sideMenuItemSpan'] {
	opacity: 0;
	transition: opacity 0.3s;
}

#main-sideMenu.collapsedAndOpenChild + .content-right {
	max-width: calc(100% - @new-sidebar-width);
	conversation {
		.add-message {
			left: @new-sidebar-width;
		}
	}
}

#main-sideMenu.side-menu-collapsed:has(.open) + .content-right {
	max-width: calc(100% - @new-sidebar-width);
	conversation {
		.add-message {
			left: @new-sidebar-width;
		}
	}
}

.mb10 {
	margin-bottom: 10px;
}
