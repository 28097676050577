.navigation {
	.navigation-menu {
		margin-left: auto;
		overflow: hidden;

		ul {
			display: flex;
			flex-wrap: wrap;
			padding: 0;
			margin: 0;
			max-width: calc(100vw - 40px);
		}
	}
}
