@import '../../../../common/styles/styles.less';

.side-menu-team-switcher {
	opacity: 1;
	display: block;
	left: @ts-unit-triple;
	bottom: 0;
	background-color: @go-sidebar-blue-dark;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	padding-top: 0;
	z-index: -1;
	transform: translateX(-100%);
	transition: transform 0.3s ease-in-out;
	width: @ts-width-aside - @ts-unit;

	&--open {
		transform: translateX(0);
	}

	&--closed {
		transform: translateX(-100%);
	}

	&::before {
		box-shadow: 3px 0 @ts-unit-half 1px rgba(0, 0, 0, 0.4);
		content: ' ';
		height: 100%;
		position: absolute;
		top: @ts-unit-triple;
		width: 1px;
		z-index: 200;
	}

	@media screen and (max-width: 650px) {
		transform: none;
		transition: none;
	}

	header {
		border-bottom: @go-black 1px solid;
		color: @go-white;
		font-size: @ts-fontsize-medium;
		font-weight: @ts-fontweight-semibold;
		height: @ts-unit;
		margin-top: 1px;
		padding: @ts-unit;
	}

	.close-drawer {
		align-items: center;
		border-radius: @ts-unit + @ts-unit-half;
		display: flex;
		flex: 1;
		height: @ts-unit + @ts-unit-half;
		justify-content: center;
		position: absolute;
		right: 0;
		top: 15px;
		transition:
			min-width 0.3s ease-out,
			max-width 0.3s ease-out;
		width: @ts-unit + @ts-unit-half;
		z-index: 1;

		&:hover {
			cursor: pointer;
		}

		i {
			background-size: 100% 100%;
			height: @ts-unit-half + @ts-unit-quarter;
			transform: scaleX(-1);
			width: @ts-unit-half + @ts-unit-quarter;
		}
	}

	.side-menu-team-switcher__menu {
		height: 100vh;
		max-height: calc(100vh - 60px);
		overflow-y: auto;

		.side-menu-panel-option {
			&:hover {
				a {
					background: @go-sidebar-blue;
				}
			}

			& > a {
				user-select: none;
				position: relative;
				width: 100%;
				padding: @ts-unit-half @ts-unit-half 9px (@ts-unit + @ts-unit-half);
				text-align: left;
				text-decoration: none;
				color: hsl(200, 21%, 92%);
				min-height: @ts-unit-triple;
				display: flex;
				flex-direction: column;

				.team-name,
				.role-name {
					padding-left: @ts-unit;
					padding-right: @ts-unit-double;
					margin-left: 0;
				}

				.team-name {
					font-weight: @ts-fontweight;
					font-size: @ts-fontsize;
				}

				.role-name {
					color: hsl(198, 20%, 59%);
					font-weight: 600;
					font-size: @ts-fontsize-small;
					text-transform: uppercase;
				}

				.ts-icon-accept {
					position: absolute;
					right: @ts-unit;
					transform: translateY(-50%);
					top: 50%;
				}
			}

			&.is-active {
				a {
					background: @go-sidebar-blue;

					.ts-icon-accept {
						color: #00b0ff;
					}
				}
			}
		}

		.team-switcher-item {
			a {
				padding: @ts-unit-half;
				div {
					margin-left: @ts-unit + @ts-unit-half;
				}
			}
		}
	}
}
